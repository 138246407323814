
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { pt } from "yup-locale-pt";

export default defineComponent({
  name: "check-laudo",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    Yup.setLocale(pt);

    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);
    const toc = ref(false);
    

    //Create form validation object
    const login = Yup.object().shape({
      renavam: Yup.number().required().label("Renavam"),
      placa: Yup.string().min(7).required().label("Placa")
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      ApiService.post("laudo/procurar", values)
        .then(({ data }) => {
          const now = new Date();
          now.setDate(now.getDate() + 1);
          const item = {
            value: true,
            expiry: now.getTime()
          };
          window.localStorage.setItem(`search-${data}`, JSON.stringify(item))
          router.push({ name: "laudo", params: { laudoId: data } });
        })
        .catch(() => {
          Swal.fire({
            text: "Laudo não encontrado, verifique os dados informados",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Tente de novo!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const setToc = () => {
      toc.value = !toc.value;
    }
    return {
      onSubmitLogin,
      login,
      submitButton,
      toc,
      setToc
    };
  },
});
